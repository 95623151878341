import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Note variant="warning" mdxType="Note">
  <Text sx={{
        display: 'block',
        fontWeight: 'bold',
        mb: 2
      }} mdxType="Text">Guidelines for this component are in progress</Text>
  <Text mdxType="Text">Interested in contributing? Review the guidelines for <Link as={GatsbyLink} to="/guides/contribute/documentation#documenting-components" mdxType="Link">writing component documentation</Link> and open a pull request in our <Link as={GatsbyLink} to="https://github.com/primer/design" mdxType="Link">documentation repository.</Link></Text>
    </Note>
    <h2>{`Accessibility and usability expectations`}</h2>
    <p>{`This component is intended to show a truncated version of its content, with the option for the user to expand it to see the content in its entirety.`}</p>
    <h3>{`Built-in accessibility features`}</h3>
    <p>{`The component uses CSS to truncate text to a fixed size, with a `}<inlineCode parentName="p">{`title`}</inlineCode>{` attribute – containing the full content of the component – that triggers a native browser tooltip when the component is hovered with the mouse. This means that currently, the component is not accessible to keyboard users, as there is no browser-native mechanism to show `}<inlineCode parentName="p">{`title`}</inlineCode>{` tooltips when navigating with a keyboard.`}</p>
    <p>{`As the truncation is done purely via CSS, screen reader users will be able to read the entire content of the component. `}</p>
    <h3>{`Implementation requirements`}</h3>
    <p>{`Due to the current shortcomings of this component, you should consider alternative ways to provide a shortened/truncated version of content.`}</p>
    <p>{`The specific way to achieve this will depend on the context of what you are building. For instance, you could use a truncated version of the content in your actual markup, rather than relying on CSS to truncate content to a specific length. Then, provide the full content in a subsequent details view, or in a dialog that shows more information for an individual item. Alternatively, provide a toggle or user setting that allows users to switch all instances of truncated content to the full, non-truncated version of the content.`}</p>
    <h3>{`How to test the component`}</h3>
    <h4>{`Integration tests`}</h4>
    <ul>
      <li parentName="ul">{`Whenever the truncated content is presented to the user, there is a way for users to see the content in full. This method must work for all users – including keyboard users.`}</li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Truncate" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      